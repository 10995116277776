import { Battery } from './useBatteryConfig';
import { datumToString, useLiveData } from '../useLiveData';

export function useTotalBatteryHealthLiveData(batteries: Battery[]) {
  const topics = batteries.map(battery =>
    datumToString({
      source: 'measurement',
      clientId: battery.plantId,
      objectId: 'battery',
      objectIdNo: battery.id,
      pvId: 'state_of_health',
      pvIdNo: 0,
    })
  );

  const [data] = useLiveData(topics);

  const healthSum = Object.entries(data || {}).reduce((prev, [_, value]) => {
    return prev + (value.value || 0);
  }, 0);

  const countNonEmptyHealth = Object.entries(data || {}).reduce(function (prev, [_, value]) {
    if (value.value !== undefined) {
      return prev + 1;
    } else {
      return prev;
    }
  }, 0);

  // healthAvg goes from [0, 100]
  const healthAvg = countNonEmptyHealth === 0 ? undefined : healthSum / countNonEmptyHealth;

  const totalHealthGaugeValue = healthAvg === undefined ? undefined : Math.round(healthAvg / 10);
  return totalHealthGaugeValue;
}
