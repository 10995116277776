import { UseQueryResult, useQuery } from 'react-query';
import { db } from 'helpers/api';

export interface Battery {
  id: number;
  name: string;
  plantId: string;
  nominalCapacity?: number | null;
  usableCapacity?: number | null;
  nominalVoltage?: number | null;
  cutoffVoltage?: number | null;
  cRate?: number | null;
  beginningOfLife?: Date | null;
  asset?: Asset;
}

export interface Asset {
  operatingState?: number;
  inverter?: {
    totalEffectivePower?: {
      value?: number;
      phases?: {
        A?: number;
        B?: number;
        C?: number;
      };
    };
    totalApparentPower?: {
      value?: number;
      phases?: {
        A?: number;
        B?: number;
        C?: number;
      };
    };
    totalReactivePower?: {
      value?: number;
      phases?: {
        A?: number;
        B?: number;
        C?: number;
      };
    };
    powerFactor?: number;
    frequency?: number;
  };
  batteries?: {
    minModuleTemp?: number;
    averageModuleTemp?: number;
    maxModuleTemp?: number;
    stateOfChargeMin?: number;
    stateOfCharge?: number;
    stateOfChargeMax?: number;
    minModuleVoltage?: number;
    averageModuleVoltage?: number;
    maxModuleVoltage?: number;
    cRate?: number;
  };
}

async function getBatteries(plantId: string | undefined) {
  if (!plantId) {
    return [];
  }
  const res = await db.get<Battery[]>(`/config/battery/${plantId}`);
  return res.data;
}

async function getBattery(plantId: string, batteryId: string | number) {
  const res = await db.get<Battery>(`config/battery/${plantId}/${batteryId}`);
  return res.data;
}

export function useBatteryConfig(
  plantId: string,
  batteryId: string | number,
  options?: { enabled: boolean }
): UseQueryResult<Battery, unknown> {
  const batteryQuery = useQuery(['battery', plantId, batteryId], () => getBattery(plantId, batteryId), {
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
    enabled: options?.enabled ?? true,
  });
  return batteryQuery;
}

export function useBatteryConfigs(plantId: string | undefined): UseQueryResult<Battery[], unknown> {
  const batteries = useQuery(['batteries', plantId], () => getBatteries(plantId), {
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
  });
  return batteries;
}
