import { BusBar, Grid } from 'app/Plant/SingleLine/SingleLineCircuit';
import { SingleLineGroup } from 'app/Plant/SingleLine/';
import { useLiveDataMap, LiveDataConfig } from '../useLiveData';
import { Asset } from './useBatteryConfig';

interface SingleLineDataProps {
  busBar: BusBar[];
  grid: Grid;
  alerts: string[];
  asset: Asset;
}

export function useSingleLineData(clientId: string, groups: SingleLineGroup[]): SingleLineDataProps {
  // Definition of config (same as in useLiveData.tsx, pending export)
  const liveDataConfig: LiveDataConfig = {};

  // groups variable is necessary to create n frequencies and voltages per busBarIndex
  groups.forEach(group => {
    const busbar_index = group.busBarIndex;
    liveDataConfig[`frequency${busbar_index}`] = { source: 'measurement', objectId: 'busbar', objectIdNo: busbar_index, pvId: 'frequency' };
    liveDataConfig[`voltage${busbar_index}`] = { source: 'measurement', objectId: 'busbar', objectIdNo: busbar_index, pvId: 'voltage' };
  });

  const [busBarData] = useLiveDataMap(liveDataConfig, { clientId });

  const busBar: BusBar[] = groups.map((group, i) => ({
    frequency: busBarData[`frequency${i}`]?.value ?? 0,
    voltage: busBarData[`voltage${i}`]?.value ?? 0,
  }));

  const [gridData] = useLiveDataMap(
    {
      frequency: { source: 'measurement', objectId: 'grid', pvId: 'frequency' },
      voltage: { source: 'measurement', objectId: 'grid', pvId: 'voltage' },
      closed: { source: 'measurement', objectId: 'grid', pvId: 'closed' },
    },
    { clientId, objectIdNo: 0 }
  );

  const grid: Grid = {
    frequency: gridData?.frequency?.value ?? 0,
    voltage: gridData?.voltage?.value ?? 0,
    closed: gridData?.closed?.value === 1 ?? false,
  };

  const [assetData] = useLiveDataMap(
    {
      operatingState: { source: 'measurement', objectId: 'battery', pvId: 'operating_state' },
      totalEffectivePowerValue: { source: 'measurement', objectId: 'battery', pvId: 'total_effective_power' },
      // totalEffectivePowerA: { source: 'measurement', objectId: 'battery', pvId: 'total_effective_power_a'},
      // totalEffectivePowerB: { source: 'measurement', objectId: 'battery', pvId: 'total_effective_power_b' },
      // totalEffectivePowerC: { source: 'measurement', objectId: 'battery', pvId: 'total_effective_power_c' },
      totalApparentPowerValue: { source: 'measurement', objectId: 'battery', pvId: 'total_apparent_power' },
      // totalApparentPowerA: { source: 'measurement', objectId: 'battery', pvId: 'total_apparent_power_a' },
      // totalApparentPowerB: { source: 'measurement', objectId: 'battery', pvId: 'total_apparent_power_b' },
      // totalApparentPowerC: { source: 'measurement', objectId: 'battery', pvId: 'total_apparent_power_c' },
      totalReactivePowerValue: { source: 'measurement', objectId: 'battery', pvId: 'total_reactive_power' },
      // totalReactivePowerA: { source: 'measurement', objectId: 'battery', pvId: 'total_reactive_power_a' },
      // totalReactivePowerB: { source: 'measurement', objectId: 'battery', pvId: 'total_reactive_power_b' },
      // totalReactivePowerC: { source: 'measurement', objectId: 'battery', pvId: 'total_reactive_power_c' },
      powerFactor: { source: 'measurement', objectId: 'battery', pvId: 'power_factor' },
      frequency: { source: 'measurement', objectId: 'battery', pvId: 'frequency' },
      minModuleTemp: { source: 'measurement', objectId: 'battery', pvId: 'min_module_temp' },
      maxModuleTemp: { source: 'measurement', objectId: 'battery', pvId: 'max_module_temp' },
      stateOfChargeMin: { source: 'measurement', objectId: 'battery', pvId: 'state_of_charge_min' },
      stateOfCharge: { source: 'measurement', objectId: 'battery', pvId: 'state_of_charge' },
      stateOfChargeMax: { source: 'measurement', objectId: 'battery', pvId: 'state_of_charge_max' },
      minModuleVoltage: { source: 'measurement', objectId: 'battery', pvId: 'min_module_voltage' },
      // averageModuleVoltage: { source: 'measurement', objectId: 'battery', pvId: 'average_module_voltage' },
      maxModuleVoltage: { source: 'measurement', objectId: 'battery', pvId: 'max_module_voltage' },
      // cRate: { source: 'measurement', objectId: 'battery', pvId: 'c_rate' },
    },
    { clientId, objectIdNo: 0 }
  );

  const asset: Asset = {
    operatingState: assetData?.operatingState?.value ?? '',
    inverter: {
      totalEffectivePower: {
        value: assetData?.totalEffectivePowerValue?.value ?? 0,
        phases: {
          A: assetData?.totalEffectivePowerA?.value ?? 0,
          B: assetData?.totalEffectivePowerB?.value ?? 0,
          C: assetData?.totalEffectivePowerC?.value ?? 0,
        },
      },
      totalApparentPower: {
        value: assetData?.totalApparentPowerValue?.value ?? 0,
        phases: {
          A: assetData?.totalApparentPowerA?.value ?? 0,
          B: assetData?.totalApparentPowerB?.value ?? 0,
          C: assetData?.totalApparentPowerC?.value ?? 0,
        },
      },
      totalReactivePower: {
        value: assetData?.totalReactivePowerValue?.value ?? 0,
        phases: {
          A: assetData?.totalReactivePowerA?.value ?? 0,
          B: assetData?.totalReactivePowerB?.value ?? 0,
          C: assetData?.totalReactivePowerC?.value ?? 0,
        },
      },
      powerFactor: assetData?.powerFactor?.value ?? 0,
      frequency: assetData?.frequency?.value ?? 0,
    },
    batteries: {
      averageModuleTemp: assetData?.averageModuleTemp?.value ?? 0,
      maxModuleTemp: assetData?.maxModuleTemp?.value ?? 0,
      stateOfChargeMin: assetData?.stateOfChargeMin?.value ?? 0,
      stateOfCharge: assetData?.stateOfCharge?.value ?? 0,
      stateOfChargeMax: assetData?.stateOfChargeMax?.value ?? 0,
      minModuleVoltage: assetData?.minModuleVoltage?.value ?? 0,
      averageModuleVoltage: assetData?.averageModuleVoltage?.value ?? 0,
      maxModuleVoltage: assetData?.maxModuleVoltage?.value ?? 0,
      cRate: assetData?.cRate?.value ?? 0,
    },
  };

  const alerts = [
    '',
    'alert',
    'critical',
    'falsefeedback',
    'noconnect',
    'alert',
    'critical',
    'falsefeedback',
    'noconnect',
    'alert',
    'critical',
    'falsefeedback',
    'noconnect',
  ];

  // TODO: Replace with API call to fetch real data in the future

  return { alerts, busBar, grid, asset };
}
