import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from '@mui/material';
import { useCompanyId } from 'context/company';
import { DataGrid, GridColDef, GridMoreVertIcon } from '@mui/x-data-grid';
import TitledPaper from 'components/TitledPaper';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { CompanyMemberDto, Member } from 'dtos/companies/company-member.dto';
import { useCompanyMembers } from 'hooks/companies/useCompanyMembers';
import { AddUserForm } from './AddUserForm';
import { ChangeRoleModal } from './ChangeRoleModal';
import { RemoveModal } from './RemoveModal';

const columns: GridColDef[] = [
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    valueGetter: (_, row) => row?.user?.email,
  },
  {
    field: 'first_name',
    headerName: 'First Name',
    flex: 1,
    valueGetter: (_, row) => row?.user?.firstName,
  },
  {
    field: 'last_name',
    headerName: 'Last Name',
    flex: 1,
    valueGetter: (_, row) => row?.user?.lastName,
  },
  {
    field: 'role',
    headerName: 'Role',
    flex: 1,
    valueFormatter: (value?: string) => {
      if (typeof value !== 'string') return '';
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderCell: params => {
      const emailVerified = params.row?.user?.emailVerified;
      const pending = params.row?.user?.isPending;
      if (pending) {
        return <Chip size="small" label="Pending" />;
      }

      if (!emailVerified) {
        return <Chip size="small" label="Unverified" color="warning" />;
      }

      return <Chip size="small" label="Active" variant="outlined" />;
    },
  },
  {
    field: 'actions',
    headerName: '',
    width: 90,
    renderCell: params => {
      return <UserActionMenu membership={params.row} />;
    },
  },
];

export interface MemberActionProps {
  member: Member;
  onSuccess?: () => void;
}

export function UserActionMenu({ membership }: { membership: CompanyMemberDto }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [changeRoleOpen, setChangeRoleOpen] = useState(false);
  const [removeOpen, setRemoveOpen] = useState(false);
  const anchorEl = document.getElementById(`user-action-menu-${membership.user_id}`);
  return (
    <>
      <Dialog open={changeRoleOpen} onClose={() => setChangeRoleOpen(false)}>
        <ChangeRoleModal member={membership.user} onSuccess={() => setChangeRoleOpen(false)} />
      </Dialog>
      <Dialog open={removeOpen} onClose={() => setRemoveOpen(false)}>
        <RemoveModal member={membership.user} onSuccess={() => setRemoveOpen(false)} />
      </Dialog>
      <IconButton aria-label="delete" onClick={() => setMenuOpen(true)} id={`user-action-menu-${membership.user_id}`}>
        <GridMoreVertIcon />
      </IconButton>
      <Menu
        id="user-action-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <MenuItem onClick={() => setChangeRoleOpen(true)}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="button">Change Role</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setRemoveOpen(true)} color="error">
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="button">Remove</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export function CompanyMembers() {
  const [addUsersDialogOpen, setAddUsersDialogOpen] = useState(false);
  const companyId = useCompanyId();
  const { data: members, isLoading, isError } = useCompanyMembers(companyId);
  return (
    <Box>
      <Dialog open={addUsersDialogOpen} onClose={() => setAddUsersDialogOpen(false)} maxWidth="xl" fullWidth>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <AddUserForm onSuccess={() => setAddUsersDialogOpen(false)} />
        </DialogContent>
      </Dialog>
      <TitledPaper title="Users" noPadding>
        <Box sx={{ display: 'flex', justifyContent: 'end', mb: 1, px: 2 }}>
          <Button variant="contained" onClick={() => setAddUsersDialogOpen(true)}>
            Add Users
          </Button>
        </Box>
        {isLoading && <Skeleton variant="rectangular" sx={{ height: '50vh' }} />}
        {isError && <Box p={2}>An error occurred.</Box>}
        {!isLoading && !isError && (
          <DataGrid
            getRowId={row => row.user_id}
            rows={members ?? []}
            columns={columns}
            initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
            pageSizeOptions={[10, 20, 50, 100]}
            disableRowSelectionOnClick
            autoHeight
            sx={{ border: 'none' }}
          />
        )}
      </TitledPaper>
    </Box>
  );
}
