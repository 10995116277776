import React from 'react';

import { Grid } from '@mui/material';
import { useAnalysisDateRange } from 'context/AnalysisDateRange';
import useClientId from 'context/clientId';
import MapPosition from '../MapPosition';
import SpeedWindOverTime from '../Charts/SpeedWindOverTime';
import SpeedWindOverPolar from '../Charts/SpeedWindOverPolar';
import SpeedHistogram from '../Charts/SpeedHistogram';
import SpeedWindHistogram from '../Charts/SpeedWindHistogram';
import WaterDepthHistogram from '../Charts/WaterDepthHistogram';
import VesselPerformanceOverTime from '../Charts/VesselPerformanceOverTime';
import VesselPerformanceHistogram from '../Charts/VesselPerformanceHistogram';

export default function VesselGeneral() {
  const clientId = useClientId();
  const { start, end, resolution } = useAnalysisDateRange();

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} lg={9}>
          <SpeedWindOverTime clientId={clientId} from={start} to={end} resolution={resolution} aspectRatio={8} />
        </Grid>
        <Grid item xs={12} lg={3}>
          <MapPosition clientId={clientId} from={start} to={end} resolution={resolution} aspectRatio={2.7} />
        </Grid>
        <Grid item xs={6} lg={3}>
          <SpeedHistogram clientId={clientId} from={start} to={end} resolution={resolution} aspectRatio={2.7} />
        </Grid>
        <Grid item xs={6} lg={3}>
          <WaterDepthHistogram clientId={clientId} from={start} to={end} resolution={resolution} aspectRatio={2.7} />
        </Grid>
        <Grid item xs={6} lg={3}>
          <SpeedWindHistogram clientId={clientId} from={start} to={end} resolution={resolution} aspectRatio={2.7} />
        </Grid>
        <Grid item xs={6} lg={3}>
          <SpeedWindOverPolar clientId={clientId} from={start} to={end} resolution={resolution} aspectRatio={2.7} />
        </Grid>
        <Grid item xs={12} lg={9}>
          <VesselPerformanceOverTime clientId={clientId} from={start} to={end} resolution={resolution} aspectRatio={8} />
        </Grid>
        <Grid item xs={12} lg={3}>
          <VesselPerformanceHistogram clientId={clientId} from={start} to={end} resolution={resolution} aspectRatio={2.7} />
        </Grid>
      </Grid>
    </>
  );
}
