import { useVesselConfig } from 'context/vesselConfig';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { Box, Paper } from '@mui/material';
import usePathChild from 'hooks/usePathChild';
import ViewHeader, { TabLabelToPath } from 'components/ViewHeader';
import { Outlet } from 'react-router-dom';
import checkHasEnv, { Env } from '../../helpers/checkHasEnv';

const engineColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', align: 'right' as const, headerAlign: 'right' as const, width: 75 },
  { field: 'name', headerName: 'Name' },
  { field: 'nominalPower', headerName: 'Nominal Power (kW)', type: 'number', width: 160 },
  { field: 'serialNo', headerName: 'Serial Number' },
  { field: 'type', headerName: 'Engine Type' },
];

export function VesselConfigOverview() {
  const vesselConfig = useVesselConfig();

  const engineRows = Object.entries(vesselConfig.engines).map(([key, val]) => ({ ...val, id: key }));

  return (
    <Paper sx={{ minHeight: '200px', height: 'calc(100% - 128px)' }}>
      <DataGrid
        sx={{ border: 'none' }}
        rows={engineRows}
        columns={engineColumns}
        initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
        pageSizeOptions={[5]}
      />
    </Paper>
  );
}

function VesselConfig() {
  const value = usePathChild(false);
  const tabs: TabLabelToPath[] = [{ label: 'Overview', toPath: 'overview' }];

  if (!checkHasEnv(Env.Production)) {
    tabs.push({ label: 'Baselines', toPath: 'baselines' });
  }

  return (
    <Box sx={{ minWith: '500px', height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, marginBottom: 2, position: 'sticky', top: '112px', zIndex: 200 }}>
        <Box sx={{ flexGrow: 1 }}>
          <ViewHeader tabs={tabs} value={value || false} />
        </Box>
      </Box>
      <Outlet />
    </Box>
  );
}

export default VesselConfig;
