import { datumToString, useLiveDataMapped } from 'hooks/useLiveData';
import { Battery } from './useBatteryConfig';
import { BatteryStats } from 'app/Plant/BatteryTable';

export function useBatteryStats(batteries: Battery[]): BatteryStats[] {
  const [data] = useLiveDataMapped(batteriesToTopic(batteries));

  return batteries.map(battery => ({
    id: battery.id,
    name: battery.name,
    operatingState: data[`${battery.id}/operatingState`]?.value,
    soh: data[`${battery.id}/soh`]?.value,
    soc: data[`${battery.id}/soc`]?.value,
    dod: data[`${battery.id}/dod`]?.value,
    rte: data[`${battery.id}/rte`]?.value,
    equivalentFullCycles: data[`${battery.id}/equivalentFullCycles`]?.value,
    currentCRate: data[`${battery.id}/currentCRate`]?.value,
    availablePower: Number.isFinite(Math.abs(data[`${battery.id}/availablePower`]?.value))
      ? Math.abs(data[`${battery.id}/availablePower`]?.value)
      : undefined,
    input: data[`${battery.id}/totalEffectivePower`]?.value >= 0 ? data[`${battery.id}/totalEffectivePower`]?.value : 0,
    output: data[`${battery.id}/totalEffectivePower`]?.value < 0 ? Math.abs(data[`${battery.id}/totalEffectivePower`]?.value) : 0,
    totalEffectivePower: data[`${battery.id}/totalEffectivePower`]?.value, // New
    totalApparentPower: data[`${battery.id}/totalApparentPower`]?.value, // New
    totalReactivePower: data[`${battery.id}/totalReactivePower`]?.value, // New
    powerFactor: data[`${battery.id}/powerFactor`]?.value, // New
    frequency: data[`${battery.id}/frequency`]?.value, // New
    maxModuleTemp: data[`${battery.id}/maxModuleTemp`]?.value, // New
    minModuleTemp: data[`${battery.id}/minModuleTemp`]?.value, // New
    stateOfChargeMax: data[`${battery.id}/stateOfChargeMax`]?.value, // New
    stateOfChargeMin: data[`${battery.id}/stateOfChargeMin`]?.value, // New
    maxModuleVoltage: data[`${battery.id}/maxModuleVoltage`]?.value, // New
    minModuleVoltage: data[`${battery.id}/minModuleVoltage`]?.value, // New
  }));
}

function batteriesToTopic(batteries: Battery[]) {
  const measurementParams = [
    { key: 'operatingState', pvId: 'operating_state' },
    { key: 'soh', pvId: 'state_of_health' },
    { key: 'soc', pvId: 'state_of_charge' },
    { key: 'dod', pvId: 'depth_of_discharge' },
    { key: 'rte', pvId: 'round_trip_efficiency' },
    { key: 'equivalentFullCycles', pvId: 'equivalent_full_cycles' },
    { key: 'currentCRate', pvId: 'c_rate' },
    { key: 'availablePower', pvId: 'available_power' },
    { key: 'totalEffectivePower', pvId: 'total_effective_power' }, // New
    { key: 'totalApparentPower', pvId: 'total_apparent_power' }, // New
    { key: 'totalReactivePower', pvId: 'total_reactive_power' }, // New
    { key: 'status', pvId: 'status' }, // New
    { key: 'powerFactor', pvId: 'power_factor' }, // New
    { key: 'frequency', pvId: 'frequency' }, // New
    { key: 'maxModuleTemp', pvId: 'max_module_temp' }, // New
    { key: 'minModuleTemp', pvId: 'min_module_temp' }, // New
    { key: 'stateOfChargeMax', pvId: 'state_of_charge_max' }, // New
    { key: 'stateOfChargeMin', pvId: 'state_of_charge_min' }, // New
    { key: 'maxModuleVoltage', pvId: 'max_module_voltage' }, // New
    { key: 'minModuleVoltage', pvId: 'min_module_voltage' }, // New
  ];

  const topics = batteries.map(battery => {
    const batteryTopics: Record<string, string> = {};

    measurementParams.forEach(param => {
      batteryTopics[`${battery.id}/${param.key}`] = datumToString({
        source: 'measurement',
        clientId: battery.plantId,
        objectId: 'battery',
        objectIdNo: battery.id,
        pvId: param.pvId,
        pvIdNo: 0,
      });
    });

    return batteryTopics;
  });

  return Object.assign({}, ...topics);
}
