import { timeseries } from 'helpers/api';
import { useQuery } from 'react-query';
import 'reflect-metadata';
import { IsNumber, IsDate, IsOptional, IsString } from 'class-validator';
import { Type } from 'class-transformer';
import { transformAndValidate } from 'class-transformer-validator';
import { calculatePerformanceData } from './usePerformanceLiveData';

export class Trip {
  @IsNumber()
  @IsOptional()
  avgPerformance: number | undefined;

  @Type(() => Date)
  @IsDate()
  from: Date | undefined;

  @Type(() => Date)
  @IsDate()
  to: Date | undefined;

  @IsString()
  @IsOptional()
  startLocode: string | undefined;

  @IsString()
  @IsOptional()
  endLocode: string | undefined;

  @IsNumber()
  @IsOptional()
  distance: number | undefined;

  @IsNumber()
  @IsOptional()
  totalFuel: number | undefined;

  @IsNumber()
  @IsOptional()
  avgFuel: number | undefined;

  @IsNumber()
  @IsOptional()
  avgLoad: number | undefined;

  @IsNumber()
  @IsOptional()
  avgStw: number | undefined;

  @IsNumber()
  @IsOptional()
  avgSog: number | undefined;

  @IsNumber()
  @IsOptional()
  avgWind: number | undefined;

  @IsNumber()
  @IsOptional()
  totalCo2: number | undefined;
}

interface TripFilter {
  clientId: string;
}

export async function getTrips(params: TripFilter) {
  const { data } = await timeseries.get<Trip[]>(`api/trips/daily`, { params });
  const result = await transformAndValidate(Trip, data);
  return result.map(item => ({
    ...item,
    avgPerformance: calculatePerformanceData(item.avgPerformance),
  }));
}

export function useTrips(params: TripFilter) {
  return useQuery(['trips', params], () => getTrips(params));
}
