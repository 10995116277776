// src/app/Plant/SingleLine/SingleLineAsset.tsx
import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { SingleLineBESS } from '../SingleLineBESS';
import { BESS } from '../SingleLineCircuit';
import LinearGauge from 'components/Gauges/LinearGauge';
import StatusCarousel from '../StatusCarousel';
import InverterPower from './InverterPower';
import { SingleLineAlarms } from '../SingleLineAlarms';

type SingleLineAssetProps = {
  battery: BESS;
};

const SingleLineAsset: React.FC<SingleLineAssetProps> = ({ battery }) => {
  const { asset } = battery.battery;

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={3} container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              ESS
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SingleLineBESS bess={battery} onClick={() => false} sx={{ maxWidth: 'none', height: '100%', p: 3 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              Status
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 1 }}>
              <StatusCarousel operatingState={asset?.operatingState ?? 0} />
            </Paper>
          </Grid>
        </Grid>

        <Grid item xs={12} md={9} container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              Inverter
            </Typography>
          </Grid>

          <InverterPower
            label="Total Effective Power"
            unit="kW"
            value={asset?.inverter?.totalEffectivePower?.value ?? 0}
            phases={asset?.inverter?.totalEffectivePower?.phases ?? { A: 0, B: 0, C: 0 }}
          />
          <InverterPower
            label="Total Apparent Power"
            unit="kVA"
            value={asset?.inverter?.totalApparentPower?.value ?? 0}
            phases={asset?.inverter?.totalApparentPower?.phases ?? { A: 0, B: 0, C: 0 }}
          />
          <InverterPower
            label="Total Reactive Power"
            unit="var"
            value={asset?.inverter?.totalReactivePower?.value ?? 0}
            phases={asset?.inverter?.totalReactivePower?.phases ?? { A: 0, B: 0, C: 0 }}
          />

          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 1 }}>
              <LinearGauge
                type="ends"
                value={asset?.inverter?.powerFactor ?? 0}
                label="Power Factor"
                unit="cap"
                min={0}
                max={1}
                align="horizontal"
              />
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 1 }}>
              <LinearGauge
                type="ends"
                value={asset?.inverter?.frequency ?? 0}
                label="Frequency"
                unit="Hz"
                min={0}
                max={60}
                align="horizontal"
              />
            </Paper>
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              Batteries
            </Typography>
          </Grid>
          <Grid item>
            <Paper elevation={3} sx={{ p: 1 }}>
              <LinearGauge value={asset?.batteries?.minModuleTemp ?? 0} label="Min Module Temp" unit="°C" />
            </Paper>
          </Grid>

          <Grid item>
            <Paper elevation={3} sx={{ p: 1 }}>
              <LinearGauge value={asset?.batteries?.maxModuleTemp ?? 0} label="Max Module Temp" unit="°C" />
            </Paper>
          </Grid>

          <Grid item>
            <Paper elevation={3} sx={{ p: 1 }}>
              <LinearGauge type="outerRed" value={asset?.batteries?.stateOfChargeMin ?? 0} label="State of Charge Min" unit="%" />
            </Paper>
          </Grid>

          <Grid item>
            <Paper elevation={3} sx={{ p: 1 }}>
              <LinearGauge type="outerRed" value={asset?.batteries?.stateOfCharge ?? 0} label="State of Charge" unit="%" />
            </Paper>
          </Grid>

          <Grid item>
            <Paper elevation={3} sx={{ p: 1 }}>
              <LinearGauge type="reversed" value={asset?.batteries?.stateOfChargeMax ?? 0} label="State of Charge Max" unit="%" />
            </Paper>
          </Grid>

          <Grid item>
            <Paper elevation={3} sx={{ p: 1 }}>
              <LinearGauge type="ends" value={asset?.batteries?.minModuleVoltage ?? 0} label="Min Module Voltage" unit="V" />
            </Paper>
          </Grid>

          {/* <Grid item>
            <Paper elevation={3} sx={{ p: 1 }}>
              <LinearGauge type="ends" value={asset?.batteries?.averageModuleVoltage ?? 0} label="Average Module Voltage" unit="V" />
            </Paper>
          </Grid> */}

          <Grid item>
            <Paper elevation={3} sx={{ p: 1 }}>
              <LinearGauge type="ends" value={asset?.batteries?.maxModuleVoltage ?? 0} label="Max Module Voltage" unit="V" />
            </Paper>
          </Grid>

          {/* <Grid item>
            <Paper elevation={3} sx={{ p: 1 }}>
              <LinearGauge type="reversed" value={asset?.batteries?.cRate ?? 0} label="C-Rate" unit="C" />
            </Paper>
          </Grid> */}
        </Grid>

        <Grid item xs={12}>
          <SingleLineAlarms clientId={battery.battery.plantId} batteryId={battery.battery.id} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleLineAsset;
