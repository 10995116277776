import React from 'react';

import { Grid } from '@mui/material';

//Exhaust
import { Bank } from '../Charts/MeanExhaustgasOverTime';
import TCSpeedOverTime from '../Charts/TCSpeedOverTime';
import { TCSpeedCombinedA, TCSpeedCombinedB } from '../Charts/TCSpeedCombined';
import EnginePowerOverTime from '../Charts/EnginePowerOverTime';
import MapPosition from '../MapPosition';

import { useAnalysisDateRange } from 'context/AnalysisDateRange';
import useClientId from 'context/clientId';

export default function EngineTC() {
  const clientId = useClientId();
  const { start, end, resolution } = useAnalysisDateRange();

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} lg={6}>
          <EnginePowerOverTime clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MapPosition clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TCSpeedOverTime bank={Bank.A} clientId={clientId} from={start} to={end} resolution={resolution} renderIndex={19} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TCSpeedCombinedA clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TCSpeedOverTime bank={Bank.B} clientId={clientId} from={start} to={end} resolution={resolution} renderIndex={20} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TCSpeedCombinedB clientId={clientId} from={start} to={end} resolution={resolution} renderIndex={21} />
        </Grid>
      </Grid>
    </>
  );
}
