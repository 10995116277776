import joi from 'joi';

import { db } from 'helpers/api';
import { useQuery } from 'react-query';
import { vesselSchema } from '../schemas/vessel';

export type VesselFeatureKey =
  | 'vibrationSensor'
  | 'oilSensor'
  | 'flowMeter'
  | 'shaftPowerMeter'
  | 'inOperationByRpm'
  | 'injectorTemperatures';

export interface VesselFeature {
  key: VesselFeatureKey;
}
export interface Vessel {
  clientId: string;
  name: string;
  features: VesselFeature[];
}

async function getFleetConfig() {
  const res = await db.get<Vessel[]>(`/config/vessels/my`);
  if (res.data) {
    const { error } = joi.array().items(vesselSchema).validate(res.data, { allowUnknown: true });
    if (error) {
      throw error;
    }
  }
  return res.data;
}

export function useFleetConfigQuery() {
  return useQuery(['fleetConfig'], () => getFleetConfig(), {
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
  });
}

export function useFleetConfig(): Vessel[] {
  const config = useFleetConfigQuery();
  return config.data ?? [];
}
