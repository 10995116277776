import { Outlet } from 'react-router-dom';
import ViewHeader, { TabLabelToPath } from 'components/ViewHeader';
import Box from '@mui/material/Box';
import usePathChild from 'hooks/usePathChild';

export default function Tasks() {
  const value = usePathChild();

  const tabs: TabLabelToPath[] = [
    { label: 'Board', toPath: 'board' },
    { label: 'Workplan', toPath: 'workplan' },
  ];

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box style={{ position: 'sticky', top: '112px', zIndex: 200 }}>
        <ViewHeader tabs={tabs} value={value || false} />
      </Box>
      <Outlet />
    </Box>
  );
}
