import { Box } from '@mui/system';
import TimeRangeSelect from 'components/TimeRangeSelect';
import ViewHeader, { TabLabelToPath } from 'components/ViewHeader';
import { AnalysisDateRangeContext } from 'context/AnalysisDateRange';
import { ZoomTimeRangeProvider } from 'context/zoom/ZoomTimeRangeProvider';
import usePathChild from 'hooks/usePathChild';
import useTimeRange from 'hooks/useTimeRange';
import React, { useState } from 'react';
import { Outlet } from 'react-router';

function Status() {
  const value = usePathChild(false);
  const { start, end, activeRange, initDate, setStart, setEnd, setActiveRange } = useTimeRange();
  const [resolution, setResolution] = useState(60);

  const tabs: TabLabelToPath[] = [
    { label: 'Health', toPath: 'health' },
    { label: 'Maintenance', toPath: 'tbo' },
    { label: 'Load Profile', toPath: 'load' },
  ];

  const setCustom = (start: Date, end: Date) => {
    setActiveRange(0);
    setStart(start);
    setEnd(end);
  };

  return (
    <ZoomTimeRangeProvider>
      <Box sx={{ minWidth: '500px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, position: 'sticky', top: '112px', zIndex: 200 }}>
          <Box sx={{ flexGrow: 1 }}>
            <ViewHeader tabs={tabs} value={value || false} />
          </Box>
          {value === 'load' && (
            <TimeRangeSelect
              end={end}
              start={start}
              setResolution={setResolution}
              activeRange={activeRange}
              initDate={initDate}
              setActiveRange={setActiveRange}
              setEnd={setEnd}
              setStart={setStart}
            />
          )}
        </Box>
        <Box sx={{ pt: 2 }}>
          <AnalysisDateRangeContext.Provider value={{ start, end, resolution, setCustom }}>
            <Outlet context={{ start, end, resolution }} />
          </AnalysisDateRangeContext.Provider>
        </Box>
      </Box>
    </ZoomTimeRangeProvider>
  );
}

export default Status;
