// src/app/Plant/SingleLine/Asset/InverterPower.tsx
import React from 'react';
import { Grid, Paper } from '@mui/material';
import LinearGauge from 'components/Gauges/LinearGauge';
import PhaseValueDisplay from './PhaseValueDisplay';

type InverterPowerProps = {
  label: string;
  unit: string;
  value?: number;
  phases?: {
    A?: number;
    B?: number;
    C?: number;
  };
};

const InverterPower: React.FC<InverterPowerProps> = ({ label, unit, value = 0, phases = {} }) => (
  <Grid item xs={12} md={4}>
    <Paper elevation={3} sx={{ p: 1 }}>
      {/* <Grid item xs={12} container spacing={0} alignItems={'center'}> */}
      {/* <Grid item xs={12} md={6}> */}
      <LinearGauge value={value} label={label} unit={unit} />
      {/* </Grid> */}
      {/* <Grid item xs={12} md={6} sx={{ borderLeft: '1px solid #e0e0e0' }}>
          <PhaseValueDisplay phase="A" value={phases.A ?? 0} unit={unit} />
          <PhaseValueDisplay phase="B" value={phases.B ?? 0} unit={unit} />
          <PhaseValueDisplay phase="C" value={phases.C ?? 0} unit={unit} />
        </Grid> */}
      {/* </Grid> */}
    </Paper>
  </Grid>
);

export default InverterPower;
