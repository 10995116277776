import { Typography, Box } from '@mui/material';
import { useBatteryConfigs } from 'hooks/plant/useBatteryConfig';
import { useParams } from 'react-router-dom';
import { BatteryTable } from './BatteryTable';
import { Overview } from './Overview';
import { useBatteryStats } from 'hooks/plant/useBatteryStats';
import { usePlantInfo } from '../../hooks/plant/usePlantInfo';

const PlantView = () => {
  const { clientId } = useParams();
  const { data: batteries } = useBatteryConfigs(clientId);
  const stats = useBatteryStats(batteries ?? []);
  const { data: plantInfo } = usePlantInfo(clientId);

  const powerSum = stats.reduce(function (prev, a) {
    return prev + (a.availablePower || 0);
  }, 0);

  if (clientId === undefined) {
    return <Typography>No clientId provided</Typography>;
  }

  return (
    <Box sx={{ p: 4, gap: 4, display: 'flex', flexDirection: 'column' }}>
      <Overview plantName={plantInfo?.name} totalAvailablePower={powerSum} batteries={batteries || []} />
      <BatteryTable stats={stats} />
    </Box>
  );
};

export default PlantView;
