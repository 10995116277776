import { useState } from 'react';

import { Pipe, Pipeline } from 'react-pipeline-component';

import { Box, Container, CssBaseline, Paper, Typography } from '@mui/material';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom';

/// Context and Providers
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import SnackbarProvider from 'context/snackbar/SnackbarProvider';
import { ClientIdContext } from 'context/clientId';
import { useVesselConfigQuery, VesselConfigContext } from 'context/vesselConfig';
import { ThemeProvider } from '@mui/material/styles';
import SettingsProvider from 'context/settings';

/// Styles
import './app.css';
import { lightTheme } from 'theme/lightTheme';
import { darkTheme } from 'theme/darkTheme';

/// Boilerplate
import { BreadcrumbsWithVesselName, IconLabelTabs, NautiqAppBar } from './NavigationBoilerplate/BoilerplateV2';

/// Views
import FleetView from './FleetView';
import Cockpit from './Cockpit';

import Analysis from './Analysis';
import Vessel from './Analysis/Vessel';
import VesselGeneral from './Analysis/Vessel/VesselGeneral';
import VesselRoute from './Analysis/Vessel/VesselRoute';
import VesselSpeed from './Analysis/Vessel/VesselSpeed';
import VesselConsumption from './Analysis/Vessel/VesselConsumption';
import Vibration from './Analysis/Vibration';
import Engine from './Analysis/Engine';
import EngineGeneral from './Analysis/Engine/EngineGeneral';
import EngineConsumption from './Analysis/Engine/EngineConsumption';
import EngineCoolant from './Analysis/Engine/EngineCoolant';
import EngineExhaustgas from './Analysis/Engine/EngineExhaustgas';
import EngineLubOil from './Analysis/Engine/EngineLubOil';
import EngineTC from './Analysis/Engine/EngineTC';
import Trips from './Analysis/Trips';

import Task from './Tasks';
import Board from './Tasks/Board';
import Workplan from './Tasks/Workplan';

import Status from './Status';
import TBO from 'app/Status/TBO';
import VesselHealth from 'app/Status/VesselHealth';

import VesselConfig, { VesselConfigOverview } from './VesselConfig';
import { BaselineConfig, BaselineDetailsConfig } from './VesselConfig/Baselines';
import NotificationDetailsPage from './Notifications/NotificationDetails';

import ImpressumView from './Legal/ImpressumView';
import PrivacyStatementView from './Legal/PrivacyStatementView';

// Company stuff
import { CompaniesPage } from './Companies/SuperAdminViews/CompaniesPage';
import { CompanyList } from './Companies/SuperAdminViews/CompanyList';
import { CreateCompanyForm } from './Companies/SuperAdminViews/CreateCompanyForm';

import { CompanyRedirect } from './Companies/CompanyRedirect';
import { CompanyPage } from './Companies/AdminViews/CompanyPage';
import { CompanyVesselsPage } from './Companies/AdminViews/CompanyVesselsPage';
import { MyCompaniesPage } from './Companies/AdminViews/MyCompaniesPage';

/// Dialogs
import FeedbackDialog from 'components/FeedbackDialog';
import SettingsDialog from 'components/SettingsDialog';

/// auth stuff
import { Login } from './Auth/Login';

/// Misc
import Background from 'components/Background';
import { CenteredNotFoundSection } from 'components/NotFoundSection';
import { ScrollToTop } from '../helpers/scroll';
import ThemeSettingsProvider, { ThemeSettingsContext } from 'context/themeSettings';
import DemoAccessModal from 'components/DemoAccessModal';
import { CheckInbox } from './Auth/CheckInbox';
import { EmailVerified } from './Auth/EmailVerified';
import { APP_BAR_HEIGHT, BREADCRUMBS_HEIGHT, TAB_BAR_HEIGHT } from 'helpers/constants';
import NotificationPage from './Notifications';
import ContactAdmin from './FleetView/ContactAdmin';
import PlantView from './Plant';
import { PlantContainer } from './Plant/Boilerplate';
import { BridgeView } from './Bridge';
import { BridgeContainer } from './Bridge/Boilerplate';
import { useFleetConfigQuery } from 'hooks/fleetConfig';
import { BatteryDetails } from './Plant/BatteryDetails';
import LoadProfileBoilerplate from './Status/LoadProfile/Boilerplate';
import LoadProfileDetails from './Status/LoadProfile/Details';
import { CompleteRegistration } from './Auth/CompleteRegistration';
import { EmissionsPage } from './Analysis/Emissions';
import { RequestPasswordReset } from './Auth/ForgotPassword/RequestPasswordReset';
import { CheckInboxToReset } from './Auth/ForgotPassword/CheckInboxToReset';
import { ResetPassword } from './Auth/ForgotPassword/ResetPassword';
import { useMyPlants } from 'hooks/plant/useMyPlants';

import { SingleLine } from './Plant/SingleLine';
import { Alarms } from './Plant/Alarms/Alarms';
import { AlarmDetails } from './Plant/Alarms/AlarmDetails';

const queryClient = new QueryClient();

function VesselBoilerplate({ children }: { children: React.ReactNode }) {
  return (
    <Box>
      <Paper sx={{ position: 'sticky', top: 0, zIndex: theme => theme.zIndex.drawer + 1 }}>
        <BreadcrumbsWithVesselName />
        <IconLabelTabs />
      </Paper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          minHeight: `calc(100vh - (${APP_BAR_HEIGHT}px + ${BREADCRUMBS_HEIGHT}px + ${TAB_BAR_HEIGHT}px))`,
        }}>
        <Box component="main" sx={{ flexGrow: 1, p: { xs: 2, lg: 3 }, width: '100vw', maxWidth: '100%' }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
}

function VesselView() {
  const { clientId } = useParams();
  const { config, isVesselLoading, failureCount } = useVesselConfigQuery(clientId ?? '');

  if (clientId === undefined) {
    return <Typography>No clientId provided</Typography>;
  }

  if (failureCount > 0) {
    return <CenteredNotFoundSection />;
  }

  if (isVesselLoading) {
    return <></>;
  }

  return (
    <ClientIdContext.Provider value={clientId}>
      <VesselConfigContext.Provider value={config}>
        <VesselBoilerplate>
          <Outlet />
        </VesselBoilerplate>
      </VesselConfigContext.Provider>
    </ClientIdContext.Provider>
  );
}

const NautIQContainer: React.FC = ({ children }) => {
  return (
    <>
      <Paper>
        <NautiqAppBar />
      </Paper>
      <Box id="route-box" sx={{ height: `calc(100vh - ${APP_BAR_HEIGHT}px)` }}>
        {children}
      </Box>
    </>
  );
};

function RootRedirect() {
  const { data, isLoading, isSuccess } = useFleetConfigQuery();
  const { data: plantData, isLoading: plantLoading, isSuccess: plantSuccess } = useMyPlants();

  if (isLoading) {
    return <Box></Box>;
  }

  if (isSuccess && data) {
    if (data.length === 1) {
      return <Navigate to={`/vessel/${data[0].clientId}`} replace />;
    }
    if (data.length > 1) {
      return <Navigate to="fleet" replace />;
    }

    // no vessels, contact admin
    // fall trough to plants
  }

  if (plantLoading) {
    return <Box></Box>;
  }
  if (plantSuccess && plantData) {
    if (plantData.length === 1) {
      return <Navigate to={`/plant/${plantData[0].id}`} replace />;
    }
    if (plantData.length > 1) {
      // TODO: implement plant overview
      return <Navigate to={`/plant/${plantData[0].id}`} replace />;
    }

    // no plants
    // fall through
  }

  return <Navigate to="not-much-to-see-here" replace />;
}

function BatteryDetailsView() {
  const { clientId, batteryId } = useParams();
  if (!clientId) {
    return <Box>No clientId provided</Box>;
  }

  if (!batteryId) {
    return <Box>No batteryId provided</Box>;
  }

  return <BatteryDetails clientId={clientId} batteryIdNo={+batteryId} />;
}

function SingleLineView() {
  const { clientId } = useParams();
  if (!clientId) {
    return <Box>No clientId provided</Box>;
  }

  return <SingleLine clientId={clientId} />;
}

export default function App() {
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  return (
    <Pipeline
      components={[
        <ThemeSettingsProvider children={<Pipe />} />,
        <ThemeSettingsContext.Consumer>
          {({ theme }) => <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme} children={<Pipe />} />}
        </ThemeSettingsContext.Consumer>,
        <CssBaseline enableColorScheme children={<Pipe />} />,
        <QueryClientProvider client={queryClient} children={<Pipe />} />,
        <SettingsProvider children={<Pipe />} />,
        <SnackbarProvider children={<Pipe />} />,
      ]}>
      <BrowserRouter>
        <Background>
          <FeedbackDialog open={feedbackOpen} onClose={() => setFeedbackOpen(false)} />
          <SettingsDialog />
          <ScrollToTop />

          <Routes>
            <Route path="login" element={<Login />} />
            <Route
              path="plant/:clientId"
              element={
                <PlantContainer>
                  <Outlet />
                </PlantContainer>
              }>
              <Route path="" element={<PlantView />}></Route>
              <Route path="battery/:batteryId" element={<BatteryDetailsView />} />
              <Route path="singleline" element={<SingleLineView />} />
              <Route path="singleline/:group" element={<SingleLineView />} />
              <Route path="singleline/:group/:batteryid" element={<SingleLineView />} />
              <Route path="alarms" element={<Alarms />} />
              <Route path="alarms/:alarmId" element={<AlarmDetails />} />
            </Route>
            <Route
              path="bridge/:clientId"
              element={
                <BridgeContainer>
                  <BridgeView />
                </BridgeContainer>
              }
            />
            <Route
              path=""
              element={
                <NautIQContainer>
                  <Outlet />
                </NautIQContainer>
              }>
              <Route path="" element={<RootRedirect />} />
              <Route path="fleet" element={<FleetView />} />
              <Route path="vessel/:clientId" element={<VesselView />}>
                <Route path="" element={<Navigate to="cockpit" replace />} />
                <Route path="cockpit" element={<Cockpit />}></Route>
                <Route path="analysis" element={<Navigate to="vessel" replace />} />
                <Route path="analysis" element={<Analysis />}>
                  <Route path="vessel" element={<Navigate to="general" replace />} />
                  <Route path="vessel" element={<Vessel />}>
                    <Route path="general" element={<VesselGeneral />} />
                    <Route path="route" element={<VesselRoute />} />
                    <Route path="speed" element={<VesselSpeed />} />
                    <Route path="consumption" element={<VesselConsumption />} />
                  </Route>
                  <Route path="engine" element={<Navigate to="general" replace />} />
                  <Route path="engine" element={<Engine />}>
                    <Route path="general" element={<EngineGeneral />} />
                    <Route path="consumption" element={<EngineConsumption />} />
                    <Route path="lubrication" element={<EngineLubOil />} />
                    <Route path="coolant" element={<EngineCoolant />} />
                    <Route path="turbo_charger" element={<EngineTC />} />
                    <Route path="exhaustgas" element={<EngineExhaustgas />} />
                  </Route>
                  <Route path="vibration" element={<Vibration />} />
                  <Route path="trips" element={<Trips />} />
                  <Route path="emissions" element={<EmissionsPage />} />
                </Route>
                <Route path="status" element={<Navigate to="health" replace />} />
                <Route path="status" element={<Status />}>
                  <Route path="health" element={<VesselHealth />} />
                  <Route path="tbo" element={<TBO />} />
                  <Route path="load" element={<LoadProfileBoilerplate />}>
                    <Route path=":objectNo" element={<LoadProfileDetails />} />
                  </Route>
                </Route>
                <Route path="notifications" element={<NotificationPage />} />
                <Route path="notifications/details/:id" element={<NotificationDetailsPage />} />
                <Route path="tasks" element={<Navigate to="board" replace />} />
                <Route path="tasks" element={<Task />}>
                  <Route path="board/*" element={<Board />} />
                  <Route path="workplan" element={<Workplan />} />
                </Route>
                <Route path="vessel-config" element={<Navigate to="overview" replace />} />
                <Route path="vessel-config" element={<VesselConfig />}>
                  <Route path="overview" element={<VesselConfigOverview />} />
                  <Route path="baselines" element={<Navigate to="list" replace />} />
                  <Route path="baselines">
                    <Route path="list" element={<BaselineConfig />} />
                    <Route path="details/:baselineGroupId" element={<BaselineDetailsConfig />} />
                  </Route>
                </Route>
                <Route path="*" element={<CenteredNotFoundSection />} />
              </Route>
              <Route path="companies" element={<CompaniesPage />}>
                <Route path="" element={<Navigate to={'company-list'} replace />} />
                <Route path="company-list" element={<CompanyList />} />
                <Route path="create-company" element={<CreateCompanyForm />} />
              </Route>
              <Route path="my-companies" element={<MyCompaniesPage />} />
              <Route path="company" element={<CompanyRedirect />} />
              <Route path="company/:companyId" element={<CompanyPage />} />
              <Route path="company/:companyId/vessels" element={<CompanyVesselsPage />} />
              <Route path="/feedback" element={<FeedbackDialog open={true} />} />
              {/*
                // TODO: figure out how to do GDPR save register :)
                <Route path="/register" element={<Register />} />
              */}
              <Route path="/contact-admin" element={<ContactAdmin />} />
              <Route path="/not-much-to-see-here" element={<NotMuchToSeeHere />} />
              <Route path="/complete-registration" element={<CompleteRegistration />} />
              <Route path="/check-inbox" element={<CheckInbox />} />
              <Route path="/email-verified" element={<EmailVerified />} />
              <Route path="/request-password-reset" element={<RequestPasswordReset />} />
              <Route path="/check-inbox-to-reset" element={<CheckInboxToReset />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="404" element={<CenteredNotFoundSection />} />
              <Route path="demo_access" element={<DemoAccessModal open demoAccessStatus="no_token" />} />
              <Route path="impressum" element={<ImpressumView />} />
              <Route path="privacy" element={<PrivacyStatementView />} />
              <Route path="*" element={<CenteredNotFoundSection />} />
            </Route>
          </Routes>
          {/* process.env.NODE_ENV === 'development' ? <ReactQueryDevtools /> : undefined */}
          {process.env.NODE_ENV === 'development' ? <ReactQueryDevtools /> : undefined}
        </Background>
      </BrowserRouter>
    </Pipeline>
  );
}

function NotMuchToSeeHere() {
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
        flexDirection: 'column',
      }}>
      <Typography
        variant="h4"
        sx={{
          mb: 3,
          fontWeight: 'bold',
        }}>
        Not much to see here
      </Typography>
      <Typography>
        There doesn't seem to be anything configured for you. Either you've encountered a bug in our system, or we don't receive any data.
      </Typography>
    </Container>
  );
}
