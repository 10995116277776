import React from 'react';

import { Grid } from '@mui/material';
import { useAnalysisDateRange } from 'context/AnalysisDateRange';
import useClientId from 'context/clientId';
import MapPosition from '../MapPosition';
import SpeedWindOverTime from '../Charts/SpeedWindOverTime';

export default function VesselRoute() {
  const clientId = useClientId();
  const { start, end, resolution } = useAnalysisDateRange();

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} lg={12}>
          <SpeedWindOverTime clientId={clientId} from={start} to={end} resolution={resolution} aspectRatio={8} />
        </Grid>
        <Grid item xs={12} lg={12}>
          <MapPosition clientId={clientId} from={start} to={end} resolution={resolution} aspectRatio={5} />
        </Grid>
      </Grid>
    </>
  );
}
