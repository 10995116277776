import React, { useState } from 'react';

import moment from 'moment';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import ViewHeader, { TabLabelToPath } from 'components/ViewHeader';

import usePathChild from 'hooks/usePathChild';
import useTimeRange from 'hooks/useTimeRange';
import 'moment/locale/en-gb';
import { ZoomTimeRangeProvider } from 'context/zoom/ZoomTimeRangeProvider';
import useClientId from '../../context/clientId';
import { useVesselFeatures } from 'hooks/useVesselFeatures';
import TimeRangeSelect from 'components/TimeRangeSelect';
import { AnalysisDateRangeContext } from 'context/AnalysisDateRange';

export default function Analysis() {
  const value = usePathChild(false);
  const clientId = useClientId();
  const vesselProperties = useVesselFeatures(clientId);
  const isDatePickerDisabled = value === 'trips' || value === 'emissions';

  const tabs: TabLabelToPath[] = [
    { label: 'Vessel', toPath: 'vessel' },
    { label: 'Engine', toPath: 'engine' },
    ...(vesselProperties?.includes('vibrationSensor') ? [{ label: 'Vibration', toPath: 'vibration' }] : []),
    { label: 'Trips', toPath: 'trips' },
    { label: 'Emissions', toPath: 'emissions' },
  ];

  const { start, end, activeRange, initDate, setStart, setEnd, setActiveRange } = useTimeRange();
  const [resolution, setResolution] = useState(60);

  moment().locale('en-gb');

  const setCustom = (start: Date, end: Date) => {
    setActiveRange(0);
    setStart(start);
    setEnd(end);
  };

  return (
    <ZoomTimeRangeProvider setResolution={setResolution} setActiveRange={setActiveRange} setEnd={setEnd} setStart={setStart}>
      <Box sx={{ minWidth: '500px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, position: 'sticky', top: '112px', zIndex: 200 }}>
          <Box sx={{ flexGrow: 1 }}>
            <ViewHeader tabs={tabs} value={value || false} />
          </Box>
          <TimeRangeSelect
            end={end}
            start={start}
            setResolution={setResolution}
            activeRange={activeRange}
            initDate={initDate}
            isDatePickerDisabled={isDatePickerDisabled}
            setActiveRange={setActiveRange}
            setEnd={setEnd}
            setStart={setStart}
          />
        </Box>
        <AnalysisDateRangeContext.Provider value={{ start, end, resolution, setCustom }}>
          <Outlet context={{ start, end, resolution }} />
        </AnalysisDateRangeContext.Provider>
      </Box>
    </ZoomTimeRangeProvider>
  );
}
