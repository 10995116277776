import { useParams } from 'react-router-dom';
import { Box, Skeleton, Typography } from '@mui/material';
import LoadProfileChart from './LoadProfileChart';
import useClientId from 'context/clientId';
import { useRunningHoursTimeseries } from 'hooks/useRunningHours';
import { FactCheckOutlined, QueryStatsOutlined } from '@mui/icons-material';
import TitledPaper from 'components/TitledPaper';
import { useVesselConfig } from 'context/vesselConfig';
import { useLoadProfile } from 'hooks/useLoadProfile';
import { calculateLoadFactor, calculateLoadIndicator } from './utils';
import { useAnalysisDateRange } from 'context/AnalysisDateRange';

export default function LoadProfileDetails() {
  const { objectNo: objectNoStr } = useParams();
  const objectNo = objectNoStr ? parseInt(objectNoStr, 10) : 0;
  const clientId = useClientId();
  const { start, end } = useAnalysisDateRange();
  const config = useVesselConfig();

  const startRunningHours = useRunningHoursTimeseries({ clientId, objectIdNo: objectNo, time: start });
  const endRunningHours = useRunningHoursTimeseries({ clientId, objectIdNo: objectNo, time: end });

  const { data, isLoading } = useLoadProfile({
    from: start,
    to: end,
    clientId,
    objectIdNo: objectNo,
  });

  if (!objectNoStr) {
    return <Typography>Missing object number</Typography>;
  }

  const loadFactor = data ? calculateLoadFactor(data) : undefined;
  const loadIndicator = data ? calculateLoadIndicator(data) : undefined;

  return (
    <TitledPaper
      icon={<QueryStatsOutlined />}
      title={`Load Profile ${config.engines[objectNo]?.name} ${config.engines[objectNo]?.serialNo}`}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
          <Typography>Operation Hours</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <FactCheckOutlined />
            {startRunningHours.data && endRunningHours.data ? (
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                }}>
                {endRunningHours.data - startRunningHours.data}h
              </Typography>
            ) : (
              <Skeleton height={32} width={70} />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: '50%',
          }}>
          <LoadProfileChart data={data} isLoading={isLoading} currentLF={loadFactor} />
        </Box>
        <Box>
          {loadIndicator ? (
            <Typography>
              Load Indicator <b>{loadIndicator.toFixed(2)}</b>
            </Typography>
          ) : (
            <Skeleton sx={{ width: 140, height: 24 }} />
          )}
          {loadFactor ? (
            <Typography>
              Load Factor <b>{loadFactor.toFixed(2)}</b>
            </Typography>
          ) : (
            <Skeleton sx={{ width: 123, height: 24 }} />
          )}
        </Box>
      </Box>
    </TitledPaper>
  );
}
