enum OperatingState {
  DeEnergized = 0,
  Off = 1,
  InitializingConfiguring = 2,
  Connecting = 3,
  ColdStandby = 4,
  HotStandby = 5,
  StartingUp = 6,
  Running = 7,
  ShuttingDown = 8,
  Fault = 9,
  SafeShutdown = 10,
}

export function getOperatingState(value: number): string {
  switch (value) {
    case OperatingState.DeEnergized:
      return 'De-energized';
    case OperatingState.Off:
      return 'Off';
    case OperatingState.InitializingConfiguring:
      return 'Initializing/Configuring';
    case OperatingState.Connecting:
      return 'Connecting';
    case OperatingState.ColdStandby:
      return 'Cold Standby';
    case OperatingState.HotStandby:
      return 'Hot Standby';
    case OperatingState.StartingUp:
      return 'Starting Up';
    case OperatingState.Running:
      return 'Running';
    case OperatingState.ShuttingDown:
      return 'Shutting down';
    case OperatingState.Fault:
      return 'Fault';
    case OperatingState.SafeShutdown:
      return 'Safe Shutdown';
    default:
      return 'Unkown';
  }
}

export function getOperatingStateMap(): { [key: number]: string } {
  return Object.fromEntries(
    Object.entries(OperatingState)
      .filter(([, value]) => typeof value === 'number')
      .map(([, value]) => [value, getOperatingState(value as number)])
  );
}
