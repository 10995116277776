import React from 'react';
import { Box, Grid, Theme, useMediaQuery } from '@mui/material';

import MapLive from 'components/MapLive';
import MapOverlay from './MapOverlay';
import { byEngineDisplayOrder, useVesselConfig } from 'context/vesselConfig';
import useClientId from 'context/clientId';
import { useLiveDataMap } from 'hooks/useLiveData';
import LiveEngineStats from './LiveEngineStats';

const Cruising: React.FC = () => {
  const vesselConfig = useVesselConfig();
  const clientId = useClientId();

  const [data] = useLiveDataMap(
    {
      sog: { source: 'measurement', objectId: 'speed', pvId: 'sog' },
      stw: { source: 'measurement', objectId: 'speed', pvId: 'stw' },
      cog: { source: 'measurement', objectId: 'direction', pvId: 'cog' },
      waterDepth: { source: 'measurement', objectId: 'environment', pvId: 'waterDepth' },
      powerTotal: { source: 'calculated', objectId: 'total', pvId: 'power' },
      powerSpecific: { source: 'calculated', objectId: 'total', pvId: 'powerSpecificToSpeed' },
      generalHealth: { source: 'calculated', objectId: 'total', pvId: 'generalHealth' },
      co2Mass: { source: 'calculated', objectId: 'total', pvId: 'emissionsCO2Mass' },
      co2Specific: { source: 'calculated', objectId: 'total', pvId: 'emissionsCO2MassSpecificToSpeed' },
      focMass: { source: 'calculated', objectId: 'total', pvId: 'focMass' },
      focVol: { source: 'calculated', objectId: 'total', pvId: 'focVol' },
      focSpecific: { source: 'calculated', objectId: 'total', pvId: 'focMassSpecificToSpeed' },
      lat: { source: 'measurement', objectId: 'position', pvId: 'lat' },
      lon: { source: 'measurement', objectId: 'position', pvId: 'lon' },
      trueWindSpeed: { source: 'calculated', objectId: 'environment', pvId: 'trueWindSpeed' },
      trueWindDirection: { source: 'calculated', objectId: 'environment', pvId: 'trueWindDirection' },
    },
    { clientId }
  );
  const cogValue = data?.sog?.value >= 0.5 ? data?.cog?.value : data?.sog?.value === undefined ? undefined : 0;

  const minWidth = '850px';
  const minHeight = '50%';

  const largeScreen = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));
  const spacing = largeScreen ? 2 : 1;

  return (
    <Box sx={{ position: 'relative', mb: 1, height: '90%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mb: spacing, position: 'relative', minHeight: minHeight, minWidth: minWidth }}>
        <MapOverlay
          sog={data?.sog?.value}
          stw={data?.stw?.value}
          cog={cogValue}
          waterDepth={data?.waterDepth?.value}
          powerTotal={data?.powerTotal?.value}
          powerSpecific={data?.powerSpecific?.value}
          generalHealth={data?.generalHealth?.value}
          co2Mass={data?.co2Mass?.value}
          co2Specific={data?.co2Specific?.value}
          focVol={data?.focVol?.value}
          focMass={data?.focMass?.value}
          focSpecific={data?.focSpecific?.value}
          lat={data?.lat?.value}
          lon={data?.lon?.value}
          trueWindSpeed={data?.trueWindSpeed?.value}
          trueWindDirection={data?.trueWindDirection?.value}
        />
        <MapLive />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid container spacing={spacing}>
          {Object.entries(vesselConfig?.engines)
            .sort(byEngineDisplayOrder)
            .map(([key, engineConfig], i) => (
              <Grid
                key={i}
                item
                xs={6}
                sm={12 / Object.entries(vesselConfig?.engines).length}
                sx={{
                  display: 'flex',
                  py: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}>
                <LiveEngineStats
                  key={key}
                  objectIdNo={Number(key)}
                  engineName={engineConfig.name}
                  engineSerialNo={engineConfig.serialNo}
                  engineType={engineConfig.type}
                  engineConfig={engineConfig}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Cruising;
