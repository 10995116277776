import React, { ReactNode } from 'react';
import { useTheme, Box } from '@mui/material';
import GaugeCalculator from './DefaultGaugeCalculator';

type Props = {
  value: number;
  max: number;
  radius?: number;
  strokeWidth?: number;
  cursorWidth?: number;
  cursorColor?: string;
  foregroundColor?: string;
  backgroundColor?: string;
  showText?: boolean;
  subtitle?: string;
  icon?: ReactNode;
};

const Gauge: React.FC<Props> = ({
  value,
  max,
  radius,
  strokeWidth,
  cursorWidth,
  cursorColor,
  foregroundColor,
  backgroundColor,
  subtitle,
  showText,
  icon,
}) => {
  const theme = useTheme();
  if (foregroundColor === undefined) {
    foregroundColor = theme.palette.primary.main;
  }
  if (backgroundColor === undefined) {
    backgroundColor = theme.palette.grey[400];
  }
  if (cursorColor === undefined) {
    cursorColor = theme.palette.success.main;
  }
  const displayValue = value;

  const viewbox_height = (radius ?? 0) * 2 + (cursorWidth ?? 0);
  const viewbox_width = (radius ?? 0) * 2 + (cursorWidth ?? 0);

  const gaugeCalculator = new GaugeCalculator(2, 4, 16, value, max);

  return (
    <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
      <Box sx={{ position: 'absolute', left: '50%', top: '30%', transform: 'translate(-50%, -50%)' }}>{showText && icon && icon}</Box>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" height={viewbox_height} width={viewbox_width}>
        <circle
          className="stroke-background"
          pathLength={360}
          strokeDasharray={gaugeCalculator.backgroundStrokeDashArray}
          stroke={backgroundColor}
          fill="none"
          cx="50%"
          cy="50%"
          r={radius}
          strokeWidth={strokeWidth}
          transform={`rotate(${gaugeCalculator.rotationDegrees}, ${viewbox_width / 2}, ${viewbox_height / 2})`}
        />
        <circle
          className="stroke-foreground"
          pathLength={360}
          strokeDasharray={gaugeCalculator.indicatorStrokeDashArray}
          stroke={foregroundColor}
          fill="none"
          cx="50%"
          cy="50%"
          r={radius}
          strokeWidth={strokeWidth}
          transform={`rotate(${gaugeCalculator.rotationDegrees}, ${viewbox_width / 2}, ${viewbox_height / 2})`}
        />
        <circle
          className="cursor"
          pathLength={360}
          strokeDasharray={gaugeCalculator.cursorStrokeDashArray}
          stroke={cursorColor}
          fill="none"
          cx="50%"
          cy="50%"
          r={radius}
          strokeWidth={cursorWidth}
          transform={`rotate(${gaugeCalculator.rotationDegrees}, ${viewbox_width / 2}, ${viewbox_height / 2})`}
        />
        {showText && icon && (
          <text
            textAnchor="middle"
            x="50%"
            y="50%"
            style={{
              fill: theme.palette.text.primary,
              fontSize: theme.typography.h5.fontSize,
              lineHeight: theme.typography.h5.lineHeight,
              letterSpacing: theme.typography.h5.letterSpacing,
              fontWeight: 'bold',
            }}>
            {icon}
          </text>
        )}
        {showText && (value || value === 0) && (
          <text
            textAnchor="middle"
            x="50%"
            y="55%"
            style={{
              fill: theme.palette.text.primary,
              fontSize: theme.typography.h5.fontSize,
              lineHeight: theme.typography.h5.lineHeight,
              letterSpacing: theme.typography.h5.letterSpacing,
              fontWeight: 'bold',
            }}>
            {displayValue.toString()}
          </text>
        )}
        {showText && subtitle && (
          <text
            textAnchor="middle"
            x="50%"
            y="70%"
            style={{
              fill: theme.palette.text.primary,
              fontSize: theme.typography.body2.fontSize,
              fontWeight: theme.typography.body2.fontWeight,
              lineHeight: theme.typography.body2.lineHeight,
              letterSpacing: theme.typography.body2.letterSpacing,
            }}>
            {subtitle}
          </text>
        )}
      </svg>
    </Box>
  );
};

Gauge.defaultProps = {
  strokeWidth: 12,
  cursorWidth: 18,
  radius: 70,
  showText: true,
};

export default Gauge;
