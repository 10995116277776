import React from 'react';

import { Grid } from '@mui/material';
//General
import FocOverTime from '../Charts/FocOverTime';
import MapPosition from '../MapPosition';
import ConsumptionOverSog from '../Charts/ConsumptionOverSog';
import SfocOverLoad from '../Charts/SfocOverLoad';
import SpeedWindOverTime from '../Charts/SpeedWindOverTime';
import CurrentLogFactorOverTime from '../Charts/CurrentLogFactorOverTime';

import { useAnalysisDateRange } from 'context/AnalysisDateRange';
import useClientId from 'context/clientId';

export default function VesselConsumption() {
  const clientId = useClientId();
  const { start, end, resolution } = useAnalysisDateRange();

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} lg={6}>
          <SpeedWindOverTime clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MapPosition clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FocOverTime clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CurrentLogFactorOverTime clientId={clientId} from={start} to={end} resolution={resolution} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ConsumptionOverSog clientId={clientId} from={start} to={end} resolution={resolution} aspectRatio={2.15} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <SfocOverLoad clientId={clientId} from={start} to={end} resolution={resolution} aspectRatio={2.15} />
        </Grid>
      </Grid>
    </>
  );
}
