import { Box, Skeleton } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import TitledPaper from 'components/TitledPaper';
import { RatingDisplay } from 'app/FleetView/RatingDisplay';
import SpeedIcon from '@mui/icons-material/Speed';
import { getRatingColor } from 'helpers/getRatingColor';
import { useTrips } from 'hooks/useTrips';
import useClientId from 'context/clientId';
import { UNITS, useTargetUnits } from 'context/settings';
import valueConversion from 'helpers/valueConversion';
import { RouteOutlined } from '@mui/icons-material';
import { useCallback, useMemo } from 'react';
import { useAnalysisDateRange } from 'context/AnalysisDateRange';
import { useNavigate } from 'react-router-dom';
import { useEmissionConfig } from 'hooks/emissions';
import { ciiCalculation, ciiToRating } from 'helpers/emissions';
import { RatingIcon } from './Emissions/RatingBox';

function roundTo(value: number, decimals: number) {
  return value.toFixed(decimals);
}

function getColumns(units: {
  readonly energyPerLength: 'kWh/nm' | 'kWh/km' | 'kWh/m';
  readonly distance: (typeof UNITS)['distance'][number];
  readonly mass: (typeof UNITS)['mass'][number];
  readonly vibrationDistance: string;
  readonly pressure: (typeof UNITS)['pressure'][number];
  readonly volume: (typeof UNITS)['volume'][number];
  readonly depth: (typeof UNITS)['depth'][number];
  readonly volumeConsumption: 'ltr/h' | 'gal/h';
  readonly temperature: (typeof UNITS)['temperature'][number];
  readonly fuelMassPerLength: 'kg/nm' | 'kg/km' | 'kg/m' | 'lbs/nm' | 'lbs/km' | 'lbs/m';
  readonly power: (typeof UNITS)['power'][number];
  readonly windSpeed: (typeof UNITS)['windSpeed'][number];
  readonly massConsumption: 'kg/h' | 'lbs/h';
  readonly vesselSpeed: (typeof UNITS)['vesselSpeed'][number];
  readonly massPerEnergyConsumption: string;
}): GridColDef[] {
  return [
    {
      field: 'avgPerformance',
      headerName: 'Ø Performance',
      width: 130,
      align: 'right',
      renderCell: cellValue => (
        <RatingDisplay icon={<SpeedIcon />} value={cellValue.value} ratingColour={getRatingColor(cellValue.value)} />
      ),
    },
    {
      field: 'rating',
      headerName: 'Emission Rating',
      width: 130,
      align: 'center',
      renderCell: params =>
        params.value ? <RatingIcon rating={params.value} sx={{ width: '2.5em' }} variant="body1" square /> : undefined,
    },
    {
      field: 'from',
      headerName: 'Date',
      width: 130,
      type: 'date',
    },
    {
      field: 'startLocode',
      headerName: 'From',
      width: 80,
      valueFormatter: (value?: string) => {
        if (!value) return '';
        return value as string;
      },
    },
    {
      field: 'endLocode',
      headerName: 'To',
      width: 80,
      valueFormatter: (value?: string) => {
        if (!value) return '';
        return value as string;
      },
    },
    {
      field: 'time',
      headerName: 'Time',
      width: 200,
      valueFormatter: (value?: { from: Date; to: Date }) => {
        if (!value) return '';
        const time = value as { from: Date; to: Date };
        return `${time.from.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - ${time.to.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })}${time.to.getDate() !== time.from.getDate() ? ` (${time.to.toLocaleDateString()})` : ''}`;
      },
    },
    {
      field: 'duration',
      headerName: 'Duration',
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (value?: number) => {
        if (!value) return '';
        return `${roundTo(value / (1000 * 60), 0)} min`;
      },
    },
    {
      field: 'distance',
      headerName: 'Distance',
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (value?: number) => {
        if (!value) return '';
        return `${roundTo(valueConversion(value, 'nm', units.distance), 1)} ${units.distance}`;
      },
    },
    {
      field: 'totalFuel',
      headerName: 'Total Fuel',
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (value?: number) => {
        if (!value) return '';
        return `${roundTo(valueConversion(value, 'ltr', units.volume), 1)} ${units.volume}`;
      },
    },
    {
      field: 'avgFuel',
      headerName: 'Ø Fuel',
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (value?: number) => {
        if (!value) return '';
        return `${roundTo(valueConversion(value, 'ltr/h', units.volumeConsumption), 1)} ${units.volumeConsumption}`;
      },
    },
    {
      field: 'avgLoad',
      headerName: 'Ø Load',
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (value?: number) => {
        if (!value) return '';
        return `${roundTo(value * 100, 1)} %`;
      },
    },
    {
      field: 'avgStw',
      headerName: 'Ø STW',
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (value?: number) => {
        if (!value) return '';
        return `${roundTo(valueConversion(value, 'kn', units.vesselSpeed), 1)} ${units.vesselSpeed}`;
      },
    },
    {
      field: 'avgSog',
      headerName: 'Ø SOG',
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (value?: number) => {
        if (!value) return '';
        return `${roundTo(valueConversion(value, 'kn', units.vesselSpeed), 1)} ${units.vesselSpeed}`;
      },
    },
    {
      field: 'avgWind',
      headerName: 'Ø Wind',
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (value?: number) => {
        if (!value) return '';
        return `${roundTo(valueConversion(value, 'm/s', units.windSpeed), 2)} ${units.windSpeed}`;
      },
    },
    {
      field: 'totalCo2',
      headerName: 'Total CO₂',
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (value?: number) => {
        if (!value) return '';
        return `${roundTo(valueConversion(value, 'kg', units.mass), 1)} ${units.mass}`;
      },
    },
  ];
}

export default function Trips() {
  const { setCustom } = useAnalysisDateRange();
  const navigate = useNavigate();
  const onRowClick: GridEventListener<'rowDoubleClick'> = useCallback(
    params => {
      const from: Date = params.row.from;
      const to: Date = params.row.to;
      const offset = 5 * 60 * 1000;
      setCustom(new Date(from.getTime() - offset), new Date(to.getTime() + offset));
      navigate(`../vessel`);
    },
    [setCustom, navigate]
  );
  const clientId = useClientId();
  const { data: emissionConfig } = useEmissionConfig(clientId);
  const units = useTargetUnits();
  const { data: rows, isLoading } = useTrips({ clientId });

  const rowsWithIndex = useMemo(
    () =>
      rows?.map((value, index) => ({
        id: index,
        time: { from: value.from, to: value.to },
        duration: value.to && value.from && value.to?.valueOf() - value.from?.valueOf(),
        ...value,
      })),
    [rows]
  );

  const rowsWithRating = useMemo(() => {
    const currentYear = new Date().getUTCFullYear();
    const ciiBoundaries = emissionConfig?.ciiBoundaries[currentYear];
    const getRating = (distance?: number, fuel?: number) => {
      if (!ciiBoundaries) {
        return undefined;
      }
      if (!distance || !fuel) {
        return undefined;
      }
      const cii = ciiCalculation({ travelledDistanceNm: distance, fuelConsumptionLiters: fuel }, emissionConfig);
      if (!cii) {
        return undefined;
      }
      return ciiToRating(cii, ciiBoundaries);
    };
    return rowsWithIndex?.map(row => ({ ...row, rating: getRating(row.distance, row.totalFuel) }));
  }, [rowsWithIndex, emissionConfig]);

  return (
    <Box sx={{ width: '100%', mt: 2, mb: 2 }}>
      <TitledPaper title="Last 7 Days" icon={<RouteOutlined />} noPadding>
        {isLoading && <Skeleton variant="rectangular" sx={{ height: '50vh' }} />}
        {rowsWithRating && (
          <DataGrid
            sx={{ border: 'none' }}
            rows={rowsWithRating}
            columns={getColumns(units)}
            initialState={{
              sorting: {
                sortModel: [{ field: 'from', sort: 'desc' }],
              },
              pagination: { paginationModel: { pageSize: 100 } },
            }}
            classes={{ row: 'clickable-row' }}
            onRowDoubleClick={onRowClick}
            pageSizeOptions={[100]}
            columnBufferPx={1000}
          />
        )}
      </TitledPaper>
    </Box>
  );
}
