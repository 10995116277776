import React from 'react';

import { Paper, Theme, useMediaQuery } from '@mui/material';

import useClientId from 'context/clientId';
import { useLiveDataMap } from 'hooks/useLiveData';

import { BarIndicator, Stat, StatCategory, StatsGrid } from './StatComponents';
import LiveEngineRPM from './LiveEngineRPM';
import type { EngineConfig } from 'hooks/useEngineConfig';
import InjectorLiveCard from '../Analysis/InjectorLiveCard';
import { useTargetUnits } from 'context/settings';
import { useVesselFeatures } from 'hooks/useVesselFeatures';
import { Vibration } from './Vibration';

function LiveEngineStats({
  objectIdNo,
  engineConfig,
}: {
  objectIdNo: number;
  engineName: string;
  engineSerialNo: string;
  engineType: string;
  engineConfig: EngineConfig;
}) {
  const clientId = useClientId();
  const vesselProperties = useVesselFeatures(clientId);
  const units = useTargetUnits();
  const [data] = useLiveDataMap(
    {
      tcRpmA: { source: 'measurement', objectId: 'combustion', pvId: 'tcRpm', pvIdNo: 0 },
      tcRpmB: { source: 'measurement', objectId: 'combustion', pvId: 'tcRpm', pvIdNo: 1 },
      tempAirIntake: { source: 'measurement', objectId: 'combustion', pvId: 'tempAirIntake' },
      tempChargeAir: { source: 'measurement', objectId: 'combustion', pvId: 'tempChargeAir' },
      pressureChargeAir: { source: 'measurement', objectId: 'combustion', pvId: 'pressureChargeAir' },
      tempLubeOil: { source: 'measurement', objectId: 'combustion', pvId: 'tempLubeOil' },
      pressureLubeOil: { source: 'measurement', objectId: 'combustion', pvId: 'pressureLubeOil' },
      tempCoolant: { source: 'measurement', objectId: 'combustion', pvId: 'tempCoolant' },
      pressureCoolant: { source: 'measurement', objectId: 'combustion', pvId: 'pressureCoolant' },
      loadReserve: { source: 'measurement', objectId: 'combustion', pvId: 'loadReserve' },
      load: { source: 'calculated', objectId: 'combustion', pvId: 'load' },
      power: { source: 'calculated', objectId: 'combustion', pvId: 'power' },
      health: { source: 'calculated', objectId: 'combustion', pvId: 'health' },
      sfoc: { source: 'calculated', objectId: 'combustion', pvId: 'focMassSpecificToPower' },
      focMass: { source: 'calculated', objectId: 'combustion', pvId: 'focMass' },
      gearboxPressureLubeOil: { source: 'measurement', objectId: 'gearbox', pvId: 'pressureLubeOil' },
      gearboxTempLubeOil: { source: 'measurement', objectId: 'gearbox', pvId: 'tempLubeOil' },
    },
    { clientId, objectIdNo }
  );
  const largeScreen = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));
  const px = largeScreen ? 2 : 1;
  return (
    <Paper sx={{ py: 2, px, width: '100%' }}>
      <LiveEngineRPM config={engineConfig} objectIdNo={objectIdNo} />
      <StatsGrid withIndicator>
        <StatCategory name="General" hideName>
          {/* todo max rpm for motor and max power from vessel settings */}
          <Stat
            value={data?.power?.value}
            name="Power"
            unit="kW"
            places={0}
            indicator={<BarIndicator maxValue={2240} currentValue={data?.power?.value} />}
          />
          <Stat
            value={data?.load?.value}
            name="Load"
            unit="%"
            indicator={<BarIndicator maxValue={100} currentValue={data?.load?.value} />}
          />
        </StatCategory>
      </StatsGrid>

      <StatsGrid>
        <StatCategory name="Turbo Charger">
          <Stat name="TC RPM A" unit="1/min" places={0} value={(x => (x !== undefined ? x * 1000 : undefined))(data?.tcRpmA?.value)} />
          <Stat name="TC RPM B" unit="1/min" places={0} value={(x => (x !== undefined ? x * 1000 : undefined))(data?.tcRpmB?.value)} />
          <Stat name="Temp. Intake Air" unit="degC" displayUnit={units.temperature} value={data?.tempAirIntake?.value} />
          <Stat name="Temp. Charge Air" unit="degC" displayUnit={units.temperature} value={data?.tempChargeAir?.value} />
          <Stat name="Press. Charge Air" unit="bar" displayUnit={units.pressure} places={2} value={data?.pressureChargeAir?.value} />
        </StatCategory>

        <StatCategory name="Consumption">
          <Stat name="Mass" value={data?.focMass?.value} unit="kg/h" places={0} displayUnit={`${units.mass}/h`} />
          <Stat name="SFOC" value={data?.sfoc?.value} unit="g/kWh" places={0} />
        </StatCategory>

        {vesselProperties.includes('oilSensor') ? (
          <StatCategory name="Lube Oil">
            <Stat name="Temp." unit="degC" displayUnit={units.temperature} value={data?.tempLubeOil?.value} />
            <Stat name="Press." unit="bar" displayUnit={units.pressure} places={2} value={data?.pressureLubeOil?.value} />
          </StatCategory>
        ) : undefined}

        <StatCategory name="Coolant">
          <Stat name="Temp." unit="degC" displayUnit={units.temperature} value={data?.tempCoolant?.value} />
          <Stat name="Press." unit="bar" displayUnit={units.pressure} places={2} value={data?.pressureCoolant?.value} />
        </StatCategory>

        <StatCategory name="Gearbox">
          <Stat name="Temp." unit="degC" displayUnit={units.temperature} value={data?.gearboxTempLubeOil?.value} />
          <Stat name="Press." unit="bar" displayUnit={units.pressure} places={2} value={data?.gearboxPressureLubeOil?.value} />
        </StatCategory>

        {vesselProperties.includes('injectorTemperatures') ? <StatCategory name="Injector" /> : undefined}
      </StatsGrid>
      {vesselProperties.includes('injectorTemperatures') ? (
        <InjectorLiveCard engineNo={objectIdNo} engine={engineConfig} clientId={clientId} withLabels={false} />
      ) : undefined}

      {vesselProperties.includes('vibrationSensor') ? <Vibration objectIdNo={objectIdNo} /> : undefined}
    </Paper>
  );
}

export default LiveEngineStats;
